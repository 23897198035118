import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db, storage, auth } from '../../../firebase'; // Import firebase storage
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import Modal from 'react-modal';
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar'; // Import Avatar for initials
import { Tooltip } from 'react-tooltip';

import EconnectMeetingModal from './EconnectMeetingModal';

import backgroundImg from '../../../images/find-bg-2.jpg';
import shapeImg from '../../../images/shape-18.png';
import imgworkSpace8 from '../../../images/workspaces-8.jpg';

import topchair from "../../../images/chairs/top-row.png";
import bottomchair from "../../../images/chairs/bottom-row.png";
import rightchair from "../../../images/chairs/right-row.png";
import leftchair from "../../../images/chairs/left-row.png";
import EventHeader from '../../../components/EventHeader';
import logo from '../../../images/Plain white.png';
import BackgroundStyle from '../../../components/BackgroundStyle';
import BackgroundStyleLight from '../../../components/BackgroundStyleLight';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};


const customStylesBio = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const VirtualOfficeSpaceUnits = () => {
  const { spaceId } = useParams();
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({}); // Fetched user details
  const [loading, setLoading] = useState(true);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isUserSeated, setIsUserSeated] = useState(false); // New state to track if the user is seated
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false); 
  const [occupiedChairs, setOccupiedChairs] = useState({}); // Track occupied chairs
  const [isInvited, setIsInvited] = useState(false);
  const [isCheckingInvitation, setIsCheckingInvitation] = useState(true);
  const [isJitsiModalOpen, setIsJitsiModalOpen] = useState(false); 

  const [selectedRoom, setSelectedRoom] = useState("");

  // Fetch user details on mount
  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid); // Fetching user details
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserDetails(userDocSnap.data());
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchUserSeatingStatus = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser || !spaceDetails) return;
  
        const userFirstName = userDetails.firstName || 'Unknown';
        const userLastName = userDetails.lastName || 'Unknown';
  
        // Check if the user is seated in any chair
        const isUserCurrentlySeated = spaceDetails.units.some(unit =>
          unit.tables.some(table =>
            table.occupiedChairs?.some(
              chair => chair.name === userFirstName && chair.surname === userLastName
            )
          )
        );
  
        setIsUserSeated(isUserCurrentlySeated);
      } catch (error) {
        console.error("Error fetching user seating status: ", error);
      }
    };
  
    if (spaceDetails) {
      fetchUserSeatingStatus();
    }
  }, [spaceDetails, userDetails]);

  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        setLoading(true);
        setIsCheckingInvitation(true);
        const user = auth.currentUser; // Get the current authenticated user

        if (!user) {
          toast.error("You need to be logged in to access this space.");
          setIsCheckingInvitation(false);
          return;
        }

        const spaceDoc = doc(db, 'virtual-offices', spaceId);
        const docSnap = await getDoc(spaceDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Check if the user's email is in the participants list
          if (data.participants?.includes(user.email)) {
            setIsInvited(true); // User is invited
          } else {
            setIsInvited(false); // User is not invited
            return; // Stop further execution if user is not invited
          }

          const createdAt = data.createdAt?.toDate();
          const formattedDate = createdAt ? createdAt.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }) : 'Unknown date';

          const unitsWithLogos = await Promise.all(
            data.units.map(async (unit) => {
              const tablesWithLogos = await Promise.all(
                unit.tables.map(async (table) => {
                  if (table.tableLogo) {
                    const tableLogoUrl = await getDownloadURL(ref(storage, table.tableLogo));
                    return { ...table, tableLogoUrl };
                  }
                  return table;
                })
              );
              return { ...unit, tables: tablesWithLogos };
            })
          );
          setSpaceDetails({ ...data, units: unitsWithLogos, formattedDate });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching space details:', error);
      } finally {
        setLoading(false);
        setIsCheckingInvitation(false);
      }
    };

    fetchSpaceDetails();
  }, [spaceId]);

  useEffect(() => {
    const spaceDocRef = doc(db, 'virtual-offices', spaceId);
    const unsubscribe = onSnapshot(spaceDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const updatedSpaceDetails = docSnapshot.data();
        setSpaceDetails(updatedSpaceDetails);
  
        // Check if the current user is already seated
        const userFirstName = userDetails.firstName || 'Unknown';
        const userLastName = userDetails.lastName || 'Unknown';
        const isUserCurrentlySeated = updatedSpaceDetails.units.some(unit =>
          unit.tables.some(table =>
            table.occupiedChairs?.some(
              chair => chair.name === userFirstName && chair.surname === userLastName
            )
          )
        );
        setIsUserSeated(isUserCurrentlySeated);
      } else {
        console.log("Space document does not exist!");
      }
    });
  
    // Clean up listener on component unmount
    return () => unsubscribe();
  }, [spaceId, userDetails]);
  

  const handleUnitClick = (unitName) => {
    if (currentUnit) {
      toast.warning(`You are already in unit: ${currentUnit}. Please exit before joining another unit.`);
      return;
    }
    setSelectedUnit(unitName);
    setCurrentUnit(unitName);
  };

  const handleEnterRoom = () => {
    setIsModalOpen(false);
    setCurrentUnit(selectedUnit);
  };

  const handleSitOnChair = async (unitName, tableIndex, chairIndex) => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === unitName);
    
    if (!unit) {
      toast.error("Unit not found.");
      return;
    }
  
    const table = unit.tables[tableIndex];
    
    if (!table) {
      toast.error("Table not found.");
      return;
    }
  
    // Initialize occupiedChairs as an empty array if it's undefined
    if (!table.occupiedChairs) {
      table.occupiedChairs = [];
    }
  
    const userFirstName = userDetails.firstName || 'Unknown';
    const userLastName = userDetails.lastName || 'Unknown';
    const userBio = userDetails.aboutMe || 'Bio not provided';
  
    // Check if the user is already seated in any chair
    const isUserAlreadySeated = spaceDetails.units.some((unit) =>
      unit.tables.some((table) =>
        table.occupiedChairs?.some(
          (chair) => chair.name === userFirstName && chair.surname === userLastName
        )
      )
    );
  
    if (isUserAlreadySeated) {
      toast.warning('You are already sitting on a chair. Please exit the current chair before selecting a new one.');
      return;
    }
  
    // Check if the chair is already occupied
    const isChairOccupied = table.occupiedChairs.some(chair => chair.index === chairIndex);
    if (isChairOccupied) {
      toast.warning('This chair is already occupied.');
      return;
    }
  
    // Add user to the chair
    table.occupiedChairs.push({
      index: chairIndex,
      name: userFirstName,
      surname: userLastName,
      aboutMe: userBio, // Include bio
    });
  
    setSpaceDetails({ ...spaceDetails });
    setIsUserSeated(true);  // Mark the user as seated
  
    try {
      // Ensure all tables have `occupiedChairs` initialized as an array
      const sanitizedUnits = spaceDetails.units.map((unit) => ({
        ...unit,
        tables: unit.tables.map((table) => ({
          ...table,
          occupiedChairs: table.occupiedChairs || [], // Ensure occupiedChairs is an array
        })),
      }));
  
      await updateDoc(doc(db, 'virtual-offices', spaceId), {
        units: sanitizedUnits,
      });
  
      toast.success('You have successfully sat on the chair.');

      setSelectedRoom(`${unitName}-meeting`); // Set the room name for Jitsi
      setIsJitsiModalOpen(true);
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error sitting on the chair.');
    }
  };
  
  
  

  const handleExitChair = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);
  
    const userFirstName = userDetails.firstName || 'Unknown';  // Fallback to 'Unknown'
    const userLastName = userDetails.lastName || 'Unknown';
  
    // Filter out the user from the occupied chairs in the current unit
    const updatedTables = unit.tables.map((table) => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        (chair) => chair.name !== userFirstName || chair.surname !== userLastName
      ),
    }));
  
    const updatedUnits = spaceDetails.units.map((u) =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );
  
    setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    setIsUserSeated(false);  // Mark the user as not seated
  
    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), {
        units: updatedUnits,
      });
      toast.success('You have exited the chair.');
    } catch (error) {
      console.error('Error exiting chair: ', error);
      toast.error('Error exiting the chair.');
    }
  };
  
  

  const handleExitRoom = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);

    const updatedTables = unit.tables.map(table => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        chair => chair.name !== userDetails.name && chair.surname !== userDetails.surname
      ),
    }));

    const updatedUnits = spaceDetails.units.map(u =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );

    setSpaceDetails({ ...spaceDetails, units: updatedUnits });

    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), {
        units: updatedUnits,
      });
      setCurrentUnit(null);
      toast.success('You have exited the room.');
    } catch (error) {
      console.error('Error exiting room: ', error);
      toast.error('Error exiting the room.');
    }
  };

  const calculateChairDistribution = (totalChairs) => {
    const maxTopBottomChairs = 4;  // Max chairs in top and bottom row
    const maxLeftRightChairs = 6;  // Max chairs in left and right row
    const rows = { topRow: 0, bottomRow: 0, leftRow: 0, rightRow: 0 };

    if (totalChairs <= maxTopBottomChairs * 2 + maxLeftRightChairs * 2) {
      const perSide = Math.floor(totalChairs / 4);
      const remaining = totalChairs % 4;

      rows.topRow = Math.min(maxTopBottomChairs, perSide + (remaining > 0 ? 1 : 0));
      rows.bottomRow = Math.min(maxTopBottomChairs, perSide + (remaining > 1 ? 1 : 0));
      rows.leftRow = Math.min(maxLeftRightChairs, perSide + (remaining > 2 ? 1 : 0));
      rows.rightRow = Math.min(maxLeftRightChairs, perSide);
    }

    return rows;
  };

  const handleAvatarClick = (chairDetails) => {
    setModalData(chairDetails); // Set the clicked user details
    setIsUserModalOpen(true); // Open the user details modal
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false); // Close the user details modal
    setModalData(null); // Clear modal data
  };

  // Ensure the user can only enter if they are invited
  if (!isInvited) {
    return <div>You do not have access to this virtual space.</div>;
  }

  const handleCloseUnitModal = () => {
    setIsUnitModalOpen(false); // Close unit modal
  };

  if (isCheckingInvitation || loading) {
    return (
      <div className="loader-container">
        <DotLoader size={60} color={'#ce2b7c'} />
      </div>
    );
  }

  if (!spaceDetails) {
    return <div>No space details found...</div>;
  }

  return (
    <div>
      {!currentUnit && (
        <section className="find-section" style={{ paddingTop: '80px' }}>
          <div className="outer-container" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="shape" style={{ backgroundImage: `url(${shapeImg})` }}></div>
            <div className="auto-container">
              <div className="sec-title-find">
                <h6>{spaceDetails.officeName}</h6>
                <h2>{spaceDetails.description}</h2>
              </div>
              <div className="inner-box">
                <ul className="list clearfix">
                  <li>
                    <div className="single-item">
                      <div className="icon-box"><i className="fas fa-couch"></i></div>
                      <h6>Type</h6>
                      <h5>Office Space</h5>
                    </div>
                  </li>
                  <li>
                    <div className="single-item">
                      <div className="icon-box"><i className="fas fa-calendar"></i></div>
                      <h6>Date</h6>
                      <h5>{spaceDetails.formattedDate}</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="workspaces-page-sectionz">
        <div className="auto-container">
          {!currentUnit && (
            <>
              <div className="short-box clearfix">
                <div className="text pull-left">
                  <p>Showing {spaceDetails.units.length} Units</p>
                </div>
              </div>
              <div className="row clearfix">
                {spaceDetails.units.map((unit, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12 workspaces-block" onClick={() => handleUnitClick(unit.unitName)}>
                    <div className="workspaces-block-one">
                      <div className="inner-box">
                        <div className="image-box">
                          <span>Virtual Space</span>
                          <figure className="image"><img src={imgworkSpace8} alt="" /></figure>
                        </div>
                        <div className="content-box">
                          <div className="text"><i className="fas fa-city"></i> Unit</div>
                          <h3><a href="workspaces-details.html">{unit.unitName}</a></h3>
                          <div className="othre-options clearfix">
                            <div className="pull-left">
                              <li><i className="fas fa-table mr-2"></i> Tables: {unit.tables.length}</li>
                            </div>
                            <ul className="pull-right clearfix">
                              <li><i className="flaticon-user"></i> {unit.tables.reduce((acc, table) => acc + table.chairs, 0)}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </section>

      {currentUnit && (
        <section className="current-unit-section blur-backgroundz">

          <BackgroundStyleLight/>
          <div className="event-header">
            <div className="logo">
              <img src={logo} alt="Logo" />
              <span>{currentUnit}</span>
            </div>
            <div className="icons">
              <i className="fas fa-users"></i>
              <div className="profile">{`${userDetails.firstName?.charAt(0)}${userDetails.lastName?.charAt(0)}`}</div>
              {isUserSeated && (
                <button onClick={handleExitChair} type="button" className="ml-2 btn btn-sm light btn-secondary">Exit Chair</button>
              )}
              <button onClick={handleExitRoom} type="button" className="ml-2 btn btn-sm light btn-danger">Leave the room</button>
            </div>
          </div>

          <div className="tables-container">
            <div className="room-layout">
              <div className="tables-grid container room-cards-container">
                {spaceDetails.units
                  .find((unit) => unit.unitName === currentUnit)
                  .tables.map((table, index) => {
                    const { topRow, rightRow, leftRow, bottomRow } = calculateChairDistribution(table.chairs);

                    return (
                      <div className="room-card" key={table.tableName}>
                        <div className="sub-card top-row">
                          {Array(topRow).fill().map((_, i) => (
                            <div className="icon_svgz" key={`top-${i}`}>
                              {table.occupiedChairs?.some(chair => chair.index === i) ? (
                                <div onClick={() => handleAvatarClick(table.occupiedChairs.find(chair => chair.index === i))}>
                                  <Avatar
                                    name={`${table.occupiedChairs.find(chair => chair.index === i).name} ${table.occupiedChairs.find(chair => chair.index === i).surname}`}
                                    size="40"
                                    round={true}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={topchair}
                                  alt="chair"
                                  onClick={() => handleSitOnChair(currentUnit, index, i)}
                                />
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="sub-card-lr right-row">
                          {Array(rightRow).fill().map((_, i) => (
                            <div className="icon_svgz" key={`right-${i}`}>
                              {table.occupiedChairs?.some(chair => chair.index === i + topRow) ? (
                                <div onClick={() => handleAvatarClick(table.occupiedChairs.find(chair => chair.index === i))}>
                                  <Avatar
                                    name={`${table.occupiedChairs.find(chair => chair.index === i + topRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow).surname}`}
                                    size="40"
                                    round={true}
                                  />
                                  <Tooltip
                                    place="top"
                                    effect="solid"
                                    content={`${table.occupiedChairs.find(chair => chair.index === i + topRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow).surname}: ${table.occupiedChairs.find(chair => chair.index === i + topRow).aboutMe}`}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={leftchair}
                                  alt="chair"
                                  onClick={() => handleSitOnChair(currentUnit, index, i + topRow)}
                                />
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="card_container">
                          <div className="centered-items">
                            {table.tableLogoUrl && (
                              <div className="table-image">
                                <img src={table.tableLogoUrl} alt="Table Logo" className="table-logo" />
                              </div>
                            )}
                            <div className="sec-title-table mt-2">
                              <h4>{table.tableName}</h4>
                            </div>
                          </div>
                        </div>

                        <div className="sub-card-lr left-row">
                          {Array(leftRow).fill().map((_, i) => (
                            <div className="icon_svgz" key={`left-${i}`}>
                              {table.occupiedChairs?.some(chair => chair.index === i + topRow + rightRow) ? (
                                <div onClick={() => handleAvatarClick(table.occupiedChairs.find(chair => chair.index === i))}>
                                  <Avatar
                                    name={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).surname}`}
                                    size="40"
                                    round={true}
                                  />
                                  <Tooltip
                                    place="top"
                                    effect="solid"
                                    content={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).surname}: ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).aboutMe}`}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={rightchair}
                                  alt="chair"
                                  onClick={() => handleSitOnChair(currentUnit, index, i + topRow + rightRow)}
                                />
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="sub-card bottom-row">
                          {Array(bottomRow).fill().map((_, i) => (
                            <div className="icon_svgz" key={`bottom-${i}`}>
                              {table.occupiedChairs?.some(chair => chair.index === i + topRow + rightRow + leftRow) ? (
                                <div onClick={() => handleAvatarClick(table.occupiedChairs.find(chair => chair.index === i))}>
                                  <Avatar
                                    name={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).surname}`}
                                    size="40"
                                    round={true}
                                  />
                                  <Tooltip
                                    place="top"
                                    effect="solid"
                                    content={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).surname}: ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).aboutMe}`}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={bottomchair}
                                  alt="chair"
                                  onClick={() => handleSitOnChair(currentUnit, index, i + topRow + rightRow + leftRow)}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="fab-wrapper">
            <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
            <label className="fabF" htmlFor="fabCheckbox">
              <span className="fab-dots fab-dots-1"></span>
              <span className="fab-dots fab-dots-2"></span>
              <span className="fab-dots fab-dots-3"></span>
            </label>
            <div className="fab-wheel">
              <a className="fab-action fab-action-1" data-tooltip-id="my-tooltip-help">
                <i className="fas fa-question"></i>
              </a>
              <a className="fab-action fab-action-2" data-tooltip-id="my-tooltip-polls">
                <i className="fas fa-poll"></i>
              </a>
              <a className="fab-action fab-action-3" data-tooltip-id="my-tooltip-qa">
                <i className="fas fa-comment-alt"></i>
              </a>
              <a className="fab-action fab-action-4" data-tooltip-id="my-tooltip-chat">
                <i className="fas fa-comments"></i>
              </a>

              <Tooltip id="my-tooltip-help" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Help!</h5>
                <p style={{color: '#fff'}}>eConnect Chat Support</p>
              </Tooltip>

              <Tooltip id="my-tooltip-chat" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Chat!</h5>
                <p style={{color: '#fff'}}>Enjoy interacting with the people in this webinar!</p>
              </Tooltip>

              <Tooltip id="my-tooltip-qa" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Q&A!</h5>
                <p style={{color: '#fff'}}>An easy way for everyone to ask questions</p>
              </Tooltip>

              <Tooltip id="my-tooltip-polls" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Polls</h5>
                <p style={{color: '#fff'}}>Get a quick pulse of the audience</p>
              </Tooltip>
            </div>
          </div>
        </section>
      )}

<Modal isOpen={isUserModalOpen} onRequestClose={handleCloseUserModal} style={customStylesBio}>
  {modalData && (
    <div className='text-center'>

<Avatar
                            name='Mduduzi Mahlangu'
                            color={"#8d14ff"}
                            size="62"
                            round={true}
                       
                          />

                          <br/>
                       
      
      <p className='mt-2'><strong>{modalData.name} {modalData.surname}</strong> </p>

      {/* Log modalData to check if bio exists */}
      {console.log("Modal Data: ", modalData)}
      <p> {modalData.aboutMe ? modalData.aboutMe : "Bio not available"}</p>


      <button onClick={handleCloseUserModal}type="button" className="btn btn-sm light btn-danger">Close</button>
    </div>
  )}
</Modal>


      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
        <h4 style={{ color: '#000' }}>Enter: <span style={{ fontWeight: 'bold' }}>{selectedUnit}</span></h4>
        <form className="profile-form">
          <div className='row'>
            <div className="col-lx-6 m-b30">
              <label className="form-label">Name:</label>
              <input
                type="text"
                className="form-control"
                required
                value={userDetails.name}
                onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
              />
            </div>
            <div className="col-lx-6 m-b30 mt-2">
              <label className="form-label">Surname:</label>
              <input
                type="text"
                className="form-control"
                required
                value={userDetails.surname}
                onChange={(e) => setUserDetails({ ...userDetails, surname: e.target.value })}
              />
            </div>
            <div className="col-lx-6 m-b30 mt-4">
              <label className="form-label">Bio:</label>
              <textarea
                rows="8"
                className="form-control"
                value={userDetails.aboutMe}
                onChange={(e) => setUserDetails({ ...userDetails, aboutMe: e.target.value })}
              />
            </div>
            <div className='card-footer'>
              <div className='text-center'>
                <button type="button" className='theme-btn btn-style-five' onClick={handleEnterRoom}>
                  Enter Room
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>


      <EconnectMeetingModal
        isOpen={isJitsiModalOpen}
        onClose={() => setIsJitsiModalOpen(false)}
        roomName={selectedRoom}
        userName={`${userDetails.firstName} ${userDetails.lastName}`}
      />
    </div>
  );
};

export default VirtualOfficeSpaceUnits;
