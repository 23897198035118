import React from "react";

import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import Avatar from "react-avatar";

import imgChart from '../dashboardstyle/images/chart.png';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const DashboardAttend = () => {
  return (
    <div>
      <div class="content-bodyz default-height">
        <div class="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                {/* Left Side */}
                <div className="col-xl-6">
                  <div class="col-xl-12">
                    <div class="card tryal-gradient-meeting">
                      <div class="card-body tryal row">
                        <div class="col-xl-7 col-sm-7">
                          <h2 class="mb-0">Attend Virtual Events in Space</h2>
                          <span>
                            Let eConnect help you organise and virtual events
                          </span>
                         
                        </div>
                        <div class="col-xl-5 col-sm-5 ">
                        <img src={imgChart} alt="" className="sd-shape"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Side */}
              

                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row">
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card bgl-primary">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 text-primary" style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-hotel'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Total Virtual Spaces</p>
                                  <h4 className="mb-0">6</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card bgl-warning">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-warning text-warning " style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-vr-cardboard'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Virtual Events</p>
                                  <h4 className="mb-0">6</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card  bgl-success">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-success text-success" style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-globe'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Hybrid Events</p>
                                  <h4 className="mb-0">6</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card bgl-sec-tz">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-sec-t text-sec-t" style={{backgroundColor: '#ffffff'}}>
                                  <i className='fas fa-user-friends'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">In-person Events</p>
                                  <h4 className="mb-0">6</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        


                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Events and Spaces</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-responsive-md">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>
                                <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                  <label
                                    class="form-check-label"
                                    for="checkAll"
                                  ></label>
                                </div>
                              </th>

                              <th>Name</th>
                              <th>Email</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                  <label
                                    class="form-check-label"
                                    for="customCheckBox2"
                                  ></label>
                                </div>
                              </td>

                              <td>
                                <div class="d-flex align-items-center">
                                  <Avatar
                                    name="Foo Bar"
                                    size="24"
                                    className="mr-2"
                                    round={true}
                                  />{" "}
                                  <span class="w-space-no">Jackson</span>
                                </div>
                              </td>
                              <td>example@example.com </td>
                              <td>01 August 2025</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <i class="fa fa-circle text-success me-1"></i>{" "}
                                  Successful
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="text-nowrap">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn light btn-danger btn-sm content-icon ml-2"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                  <label
                                    class="form-check-label"
                                    for="customCheckBox3"
                                  ></label>
                                </div>
                              </td>

                              <td>
                                <div class="d-flex align-items-center">
                                  <Avatar
                                    name="Foo Bar"
                                    size="24"
                                    className="mr-2"
                                    round={true}
                                  />{" "}
                                  <span class="w-space-no">Jackson</span>
                                </div>
                              </td>
                              <td>example@example.com </td>
                              <td>01 August 2025</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <i class="fa fa-circle text-danger me-1"></i>{" "}
                                  Canceled
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="text-nowrap">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn light btn-danger btn-sm content-icon ml-2"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                  <label
                                    class="form-check-label"
                                    for="customCheckBox4"
                                  ></label>
                                </div>
                              </td>

                              <td>
                                <div class="d-flex align-items-center">
                                  {" "}
                                  <Avatar
                                    name="Foo Bar"
                                    size="24"
                                    className="mr-2"
                                    round={true}
                                  />{" "}
                                  <span class="w-space-no">Jackson</span>
                                </div>
                              </td>
                              <td>example@example.com </td>
                              <td>01 August 2025</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <i class="fa fa-circle text-warning me-1"></i>{" "}
                                  Pending
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="text-nowrap">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn light btn-danger btn-sm content-icon ml-2"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAttend;
