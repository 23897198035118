import React, { useRef, useEffect, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

const EconnectMeetingModal = ({ isOpen, onClose, roomName, userName }) => {
  const jitsiContainerRef = useRef(null);
  const [jitsiInitialized, setJitsiInitialized] = useState(false); // Track if Jitsi is initialized
  const [loadingError, setLoadingError] = useState(null); // Track if there's an error
  const [retryCount, setRetryCount] = useState(0); // Track retries

  useEffect(() => {
    if (isOpen && !jitsiInitialized) {
      loadJitsiScript();
    }

    return () => {
      if (jitsiContainerRef.current) {
        jitsiContainerRef.current.innerHTML = ''; // Cleanup on close
        setJitsiInitialized(false); // Reset Jitsi initialization state
        setRetryCount(0); // Reset retry count
      }
    };
  }, [isOpen]);

  const loadJitsiScript = () => {
    const existingScript = document.getElementById('jitsi-iframe-api');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://meetings.e-connect.co.za/external_api.js'; // Your custom domain
      script.id = 'jitsi-iframe-api';
      script.async = true;

      script.onload = () => {
        try {
          startJitsiMeeting();
        } catch (error) {
          setLoadingError('Failed to load Jitsi meeting. Please try again.');
        }
      };

      script.onerror = () => {
        setLoadingError('Failed to load Jitsi script. Please check your network or the domain.');
      };

      document.body.appendChild(script);
    } else {
      startJitsiMeeting();
    }
  };

  const startJitsiMeeting = () => {
    if (jitsiContainerRef.current) {
      const domain = 'meetings.e-connect.co.za';
      const options = {
        roomName,
        parentNode: jitsiContainerRef.current, // Attach Jitsi to this DOM node
        userInfo: {
          displayName: userName,
        },
        configOverwrite: {
          disableInviteFunctions: true,
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          prejoinPageEnabled: false,
          disableProfile: true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          TOOLBAR_BUTTONS: ['microphone', 'camera', 'desktop', 'hangup', 'chat', 'raisehand', 'tileview'],
        },
      };

      try {
        const api = new window.JitsiMeetExternalAPI(domain, options);

        api.addListener('readyToClose', onClose); // Close modal when the meeting ends
        setJitsiInitialized(true); // Mark Jitsi as initialized
      } catch (error) {
        if (retryCount < 3) {
          setRetryCount(retryCount + 1); // Retry loading Jitsi up to 3 times
          setTimeout(() => startJitsiMeeting(), 2000); // Add slight delay before retry
        } else {
          setLoadingError('Error starting Jitsi meeting. Please try again later.');
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '80%',
        },
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      {loadingError ? (
        <div style={{ color: 'red', textAlign: 'center', padding: '20px' }}>
          {loadingError}
        </div>
      ) : (
        <div ref={jitsiContainerRef} style={{ height: '100%', width: '100%' }}>
          {!jitsiInitialized && <p>Loading meeting...</p>} {/* Show loading while initializing */}
        </div>
      )}
    </Modal>
  );
};

EconnectMeetingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default EconnectMeetingModal;
