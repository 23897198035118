import React from 'react'

const BackgroundStyleLight = () => {
  return (
    <div class="background light-back">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
 </div>
  )
}

export default BackgroundStyleLight
