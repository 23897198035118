import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase'; // Import your Firebase setup
import { collection, query, where, getDocs } from 'firebase/firestore';
import { CircleLoader } from 'react-spinners';

const EventsAttend = () => {
  const [virtualOffices, setVirtualOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const fetchVirtualOffices = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          setUserEmail(user.email);

          // Query Firestore to get all virtual offices where the user is invited
          const q = query(
            collection(db, 'virtual-offices'),
            where('participants', 'array-contains', user.email)
          );
          const querySnapshot = await getDocs(q);
          const officeList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setVirtualOffices(officeList);
        }
      } catch (error) {
        console.error('Error fetching virtual offices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVirtualOffices();
  }, []);

  if (loading) {
    return   <div className="loader-containerz">
    <CircleLoader size={50} color={"#ce2b7c"} loading={loading} />
  </div>;
  }

  

  return (
    <div>
      <div className="content-bodyx default-height">
        <div className="container-fluid">

        <div class="project-page d-flex justify-content-between align-items-center flex-wrap">
					<div class="card-tabs mb-4">
						<ul class="nav nav-tabs style-1" role="tablist">
							<li class="nav-item" role="presentation">
								<a class="nav-link active" data-bs-toggle="tab" href="#AllStatus" role="tab" aria-selected="true">Virtual Spaces</a>
							</li>
							<li class="nav-item" role="presentation">
								<a class="nav-link" data-bs-toggle="tab" href="#OnProgress" role="tab" aria-selected="false" tabindex="-1">Virtual Event</a>
							</li>
							<li class="nav-item" role="presentation">
								<a class="nav-link" data-bs-toggle="tab" href="#Pending" role="tab" aria-selected="false" tabindex="-1">Hybrid Event</a>
							</li>
							<li class="nav-item" role="presentation">
								<a class="nav-link" data-bs-toggle="tab" href="#Closed" role="tab" aria-selected="false" tabindex="-1">In-person Event</a>
							</li>
						</ul>
					</div>
				
				</div>


       

          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="AllStatus"
                  role="tabpanel"
                >
                  {virtualOffices.length === 0 ? (
                    <div className="card project-card">
                      <div className="card-body py-3 px-4">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-xl-12 col-md-12 col-sm-12 align-items-center customers">
                            <div className="media-body">
                              <h6 className="text-black">No virtual offices available</h6>
                              <p>You have not been invited to any virtual offices yet.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    virtualOffices.map((office) => (
                      <div className="card project-card" key={office.id}>
                        <div className="card-body py-3 px-4">
                          <div className="row align-items-center justify-content-between">
                            <div className="col-xl-3  col-md-4 col-sm-12 align-items-center customers">
                              <div className="media-body">
                                <p className="text-primary mb-0">#ID-{office.id}</p>
                                <h5 className="text-black" style={{fontWeight: 'bold'}}>{office.officeName}</h5>
                               
                              </div>
                            </div>

                            <div className="col-xl-3  col-md-6 col-sm-6 mt-3 mt-xl-0">
                              <div className="d-flex project-image">
                                <div>
                                  <p className="mb-0">Created: </p>
                                  <h6 className="mb-0">      <i className="fas fa-calendar me-3"></i>   {new Date(office.createdAt.seconds * 1000).toLocaleDateString()}</h6>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-2 col-sm-6 col-sm-4 mt-xl-0 mt-3">
                              <div className="d-flex justify-content-sm-end project-btn">
                                <a
                                  href={`virtual-office-space/${office.id}`} // Replace with the actual route to view the office
                                  className="badge badge-primary light badge-md"
                                >
                                  Access Virtual Space
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="mb-sm-0 mb-3">
              <p className="mb-0 text-black">
                Showing {virtualOffices.length} of {virtualOffices.length} Data
              </p>
            </div>
            <nav>
              <ul className="pagination pagination-circle">
                <li className="page-item page-indicator">
                  <a className="page-link" href="javascript:void(0)">
                    <i className="fas fa-angle-left"></i>
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="javascript:void(0)">
                    1
                  </a>
                </li>
                <li className="page-item page-indicator">
                  <a className="page-link" href="javascript:void(0)">
                    <i className="fas fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsAttend;
