import React from 'react'
import Redirect from '../Redirect'

const Home = () => {
  // return <Redirect url="https://e-connect.co.za" />;
  return (
    <div>
      Home
    </div>
  )
}



export default Home
